import { inject } from '@angular/core';
import { CanMatchFn, UrlTree } from '@angular/router';

import { Roles, RoleService } from '@core/services/role.service';

export const AdminGuard: CanMatchFn = (): boolean | UrlTree => {
  const roleService: RoleService = inject(RoleService);

  return roleService.hasAnyRole([Roles.ZLB_ADMIN])();
};
