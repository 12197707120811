import { ChangeDetectionStrategy, Component, effect, EffectRef, inject, Signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';

import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

import { NotificationService } from '@core/services/notification.service';
import { UpdateService } from '@core/services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  imports: [RouterOutlet, ToastModule, ButtonModule],
  providers: [MessageService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RootComponent {
  private config: PrimeNGConfig = inject(PrimeNGConfig);
  private notificationService: NotificationService = inject(NotificationService);
  private messageService: MessageService = inject(MessageService);
  private _updateService: UpdateService = inject(UpdateService);

  constructor() {
    this.config.setTranslation({
      dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
      ],
      monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      dateFormat: 'dd.mm.yy',
      accept: 'Ja',
      reject: 'Nein',
      today: 'Heute',
      clear: 'Löschen'
    });
  }

  private notificationStream: Signal<Message> = toSignal(this.notificationService.notificationChange$);
  private notificationEffect: EffectRef = effect(() => {
    if (this.notificationStream()) {
      untracked(() => this.messageService.add(this.notificationStream()));
    }
  });

  protected readonly window = window;
}
