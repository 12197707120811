import { effect, inject, Injectable, signal, untracked, WritableSignal } from '@angular/core';

import { catchError } from 'rxjs';

import { ClientErrorPayload } from '@core/models/clientErrorPayload';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private apiService: ApiService = inject(ApiService);

  private errorCache: Map<string, { lastLogged: number; count: number }> = new Map();
  private errorSubject: WritableSignal<ClientErrorPayload | null> = signal(null);
  private readonly DEBOUNCE_TIME = 5000; // 5 seconds
  private readonly ERROR_THRESHOLD = 3; // max 3 of the same errors within the debounce time

  constructor() {
    effect(() => {
      const errorPayload = this.errorSubject();
      if (errorPayload) {
        if (this.shouldLogError(errorPayload)) {
          this.sendError(errorPayload);
        }
      }
    });
  }

  logError(payload: ClientErrorPayload) {
    untracked(() => this.errorSubject.set(payload));
  }

  private shouldLogError(payload: ClientErrorPayload): boolean {
    const now = Date.now();
    const cachedError = this.errorCache.get(payload.message);

    if (!cachedError) {
      this.errorCache.set(payload.message, { lastLogged: now, count: 1 });
      return true;
    }

    if (now - cachedError.lastLogged < this.DEBOUNCE_TIME) {
      cachedError.count++;
      if (cachedError.count > this.ERROR_THRESHOLD) {
        return false;
      }
    } else {
      cachedError.count = 1;
      cachedError.lastLogged = now;
    }

    return true;
  }

  private sendError(body: ClientErrorPayload): void {
    this.apiService
      .post('/clientLog', body)
      .pipe(
        catchError(error => {
          console.error('Error logging to server:', error);
          return error;
        })
      )
      .subscribe(response => {
        if (response && response.message !== 'Error logged') {
          console.warn('Server logged the error but returned a non-success status:', response.message);
        }
      });
  }
}
