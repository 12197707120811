/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { AddressListEntry } from './address-list-entry';
import { RescueServiceArea } from './rescue-service-area';


export interface ControlCenter { 
    id?: string;
    created?: number;
    name?: string;
    shortName?: string;
    organization?: Organization;
    rescueServiceArea?: RescueServiceArea;
    type?: ControlCenter.TypeEnum;
    addresses?: Set<AddressListEntry>;
    www?: string;
    phoneNumber?: string;
}
export namespace ControlCenter {
    export type TypeEnum = 'ILS' | 'RLS';
    export const TypeEnum = {
        Ils: 'ILS' as TypeEnum,
        Rls: 'RLS' as TypeEnum
    };
}


