/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GPSLocation } from './gps-location';
import { Triage } from './triage';


export interface ClientEvent { 
    id?: string;
    name?: string;
    information?: string;
    gpsLocation?: GPSLocation;
    city?: string;
    objectId?: string;
    situation?: number;
    scenarioIds?: Array<string>;
    fromTime?: number;
    toTime?: number;
    triage?: Array<Triage>;
    currentLeadingUser?: string;
    hospitalIds?: Array<string>;
    activeUsers?: Set<string>;
    state?: ClientEvent.StateEnum;
}
export namespace ClientEvent {
    export type StateEnum = 'CREATED' | 'RUNNING' | 'FINISHED_ON_SITE' | 'COMPLETED';
    export const StateEnum = {
        Created: 'CREATED' as StateEnum,
        Running: 'RUNNING' as StateEnum,
        FinishedOnSite: 'FINISHED_ON_SITE' as StateEnum,
        Completed: 'COMPLETED' as StateEnum
    };
}


