/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientLandingSite { 
    id: string;
    name?: string;
    active?: ClientLandingSite.ActiveEnum;
    distance?: number;
    external?: boolean;
    hospitalId?: string;
    phoneNumber?: string;
}
export namespace ClientLandingSite {
    export type ActiveEnum = 'DAY' | 'NIGHT' | 'DAY_NIGHT' | 'DAY_VFR' | 'NIGHT_VFR' | 'DAY_NIGHT_VFR';
    export const ActiveEnum = {
        Day: 'DAY' as ActiveEnum,
        Night: 'NIGHT' as ActiveEnum,
        DayNight: 'DAY_NIGHT' as ActiveEnum,
        DayVfr: 'DAY_VFR' as ActiveEnum,
        NightVfr: 'NIGHT_VFR' as ActiveEnum,
        DayNightVfr: 'DAY_NIGHT_VFR' as ActiveEnum
    };
}


