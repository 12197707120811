/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientExceptionEntity { 
    /**
     * Type of error that was thrown (The types are only extended, never changed)
     */
    type?: ClientExceptionEntity.TypeEnum;
    /**
     * Class name of the internal exception (Do not rely on them, they change without notice)
     */
    exception?: string;
    /**
     * Developer readable message why the error occurred
     */
    message?: string;
    /**
     * Machine readable specification about the cause of the error (Id of requested entity, name of the field, name of the configuration entry)
     */
    detail?: string;
    /**
     * Request path that was called
     */
    path?: string;
    /**
     * UTC timestamp when the error occurred
     */
    timestamp?: number;
    /**
     * Developer readable reason phrase of the http status
     */
    error?: string;
    /**
     * Name of the http status
     */
    status?: string;
}
export namespace ClientExceptionEntity {
    export type TypeEnum = 'UNSPECIFIED_BAD_REQUEST' | 'CLIENT_ABORTED_CONNECTION' | 'EVENT_ATTRIBUTE_INVALID' | 'PAGE_PARAMETER_INVALID' | 'QUERY_PARAMETER_INVALID' | 'RELATED_ENTITY_MUST_NOT_BE_NULL' | 'CONCURRENT_REQUEST_FAILED' | 'INVALID_ADMIN_TASK_PARAMETERS' | 'FEATURE_NOT_ENABLED' | 'FILE_TYPE_NOT_DETECTABLE' | 'ADDRESS_INVALID' | 'ADDRESS_LIST_ENTRY_INVALID' | 'USER_GENERATED_ISSUE_STATUS_CHANGE_INVALID' | 'NOT_AUTHENTICATED' | 'LOGIN_FAILED' | 'UNSPECIFIED_AUTHORIZATION_ERROR' | 'NOT_AUTHORIZED' | 'USERNAME_ALREADY_REGISTERED' | 'ROLE_MANAGEMENT_NOT_ALLOWED' | 'REFRESH_TOKEN_INVALID' | 'UNSPECIFIED_NOT_FOUND' | 'ROLE_NOT_FOUND' | 'USER_NOT_FOUND' | 'RELATED_ENTITY_NOT_FOUND' | 'ORGANIZATION_NOT_FOUND' | 'HOSPITAL_OWNER_NOT_FOUND' | 'CONTACT_DETAIL_NOT_FOUND' | 'CONTROL_CENTER_NOT_FOUND' | 'HOSPITAL_NOT_FOUND' | 'EMERGENCY_CARE_NOT_FOUND' | 'INTENSIVE_CARE_UNIT_NOT_FOUND' | 'INTENSIVE_CARE_UNIT_BED_NOT_FOUND' | 'DEPARTMENT_NOT_FOUND' | 'DEPARTMENT_BED_NOT_FOUND' | 'DEVICE_NOT_FOUND' | 'LANDING_SITE_NOT_FOUND' | 'DIAGNOSIS_NOT_FOUND' | 'SPECIALITY_NOT_FOUND' | 'RESOURCE_NOT_FOUND' | 'FEATURE_NOT_FOUND' | 'RESCUE_SERVICE_AREA_NOT_FOUND' | 'WORKING_DIAGNOSIS_NOT_FOUND' | 'DIVONO_DEPARTMENT_CATEGORY_NOT_FOUND' | 'USER_GENERATED_ISSUE_NOT_FOUND' | 'ENDPOINT_NOT_FOUND' | 'UNSPECIFIED_EXTERNAL_ERROR' | 'UNSPECIFIED_SERVER_ERROR' | 'UNSPECIFIED_ERROR' | 'ENCRYPTION_FAILED' | 'DATA_INITIALIZATION_FAILED' | 'TEAM_NOTIFICATION_CONNECTION_CONFIG_INVALID' | 'DATA_DEPENDENCY_UNSATISFIABLE' | 'REFLECTION_FAILED' | 'FILE_STORAGE_FAILED' | 'INVALID_FEATURE_CONFIGURATION' | 'REQUEST_PROCESSING_TIMEOUT';
    export const TypeEnum = {
        UnspecifiedBadRequest: 'UNSPECIFIED_BAD_REQUEST' as TypeEnum,
        ClientAbortedConnection: 'CLIENT_ABORTED_CONNECTION' as TypeEnum,
        EventAttributeInvalid: 'EVENT_ATTRIBUTE_INVALID' as TypeEnum,
        PageParameterInvalid: 'PAGE_PARAMETER_INVALID' as TypeEnum,
        QueryParameterInvalid: 'QUERY_PARAMETER_INVALID' as TypeEnum,
        RelatedEntityMustNotBeNull: 'RELATED_ENTITY_MUST_NOT_BE_NULL' as TypeEnum,
        ConcurrentRequestFailed: 'CONCURRENT_REQUEST_FAILED' as TypeEnum,
        InvalidAdminTaskParameters: 'INVALID_ADMIN_TASK_PARAMETERS' as TypeEnum,
        FeatureNotEnabled: 'FEATURE_NOT_ENABLED' as TypeEnum,
        FileTypeNotDetectable: 'FILE_TYPE_NOT_DETECTABLE' as TypeEnum,
        AddressInvalid: 'ADDRESS_INVALID' as TypeEnum,
        AddressListEntryInvalid: 'ADDRESS_LIST_ENTRY_INVALID' as TypeEnum,
        UserGeneratedIssueStatusChangeInvalid: 'USER_GENERATED_ISSUE_STATUS_CHANGE_INVALID' as TypeEnum,
        NotAuthenticated: 'NOT_AUTHENTICATED' as TypeEnum,
        LoginFailed: 'LOGIN_FAILED' as TypeEnum,
        UnspecifiedAuthorizationError: 'UNSPECIFIED_AUTHORIZATION_ERROR' as TypeEnum,
        NotAuthorized: 'NOT_AUTHORIZED' as TypeEnum,
        UsernameAlreadyRegistered: 'USERNAME_ALREADY_REGISTERED' as TypeEnum,
        RoleManagementNotAllowed: 'ROLE_MANAGEMENT_NOT_ALLOWED' as TypeEnum,
        RefreshTokenInvalid: 'REFRESH_TOKEN_INVALID' as TypeEnum,
        UnspecifiedNotFound: 'UNSPECIFIED_NOT_FOUND' as TypeEnum,
        RoleNotFound: 'ROLE_NOT_FOUND' as TypeEnum,
        UserNotFound: 'USER_NOT_FOUND' as TypeEnum,
        RelatedEntityNotFound: 'RELATED_ENTITY_NOT_FOUND' as TypeEnum,
        OrganizationNotFound: 'ORGANIZATION_NOT_FOUND' as TypeEnum,
        HospitalOwnerNotFound: 'HOSPITAL_OWNER_NOT_FOUND' as TypeEnum,
        ContactDetailNotFound: 'CONTACT_DETAIL_NOT_FOUND' as TypeEnum,
        ControlCenterNotFound: 'CONTROL_CENTER_NOT_FOUND' as TypeEnum,
        HospitalNotFound: 'HOSPITAL_NOT_FOUND' as TypeEnum,
        EmergencyCareNotFound: 'EMERGENCY_CARE_NOT_FOUND' as TypeEnum,
        IntensiveCareUnitNotFound: 'INTENSIVE_CARE_UNIT_NOT_FOUND' as TypeEnum,
        IntensiveCareUnitBedNotFound: 'INTENSIVE_CARE_UNIT_BED_NOT_FOUND' as TypeEnum,
        DepartmentNotFound: 'DEPARTMENT_NOT_FOUND' as TypeEnum,
        DepartmentBedNotFound: 'DEPARTMENT_BED_NOT_FOUND' as TypeEnum,
        DeviceNotFound: 'DEVICE_NOT_FOUND' as TypeEnum,
        LandingSiteNotFound: 'LANDING_SITE_NOT_FOUND' as TypeEnum,
        DiagnosisNotFound: 'DIAGNOSIS_NOT_FOUND' as TypeEnum,
        SpecialityNotFound: 'SPECIALITY_NOT_FOUND' as TypeEnum,
        ResourceNotFound: 'RESOURCE_NOT_FOUND' as TypeEnum,
        FeatureNotFound: 'FEATURE_NOT_FOUND' as TypeEnum,
        RescueServiceAreaNotFound: 'RESCUE_SERVICE_AREA_NOT_FOUND' as TypeEnum,
        WorkingDiagnosisNotFound: 'WORKING_DIAGNOSIS_NOT_FOUND' as TypeEnum,
        DivonoDepartmentCategoryNotFound: 'DIVONO_DEPARTMENT_CATEGORY_NOT_FOUND' as TypeEnum,
        UserGeneratedIssueNotFound: 'USER_GENERATED_ISSUE_NOT_FOUND' as TypeEnum,
        EndpointNotFound: 'ENDPOINT_NOT_FOUND' as TypeEnum,
        UnspecifiedExternalError: 'UNSPECIFIED_EXTERNAL_ERROR' as TypeEnum,
        UnspecifiedServerError: 'UNSPECIFIED_SERVER_ERROR' as TypeEnum,
        UnspecifiedError: 'UNSPECIFIED_ERROR' as TypeEnum,
        EncryptionFailed: 'ENCRYPTION_FAILED' as TypeEnum,
        DataInitializationFailed: 'DATA_INITIALIZATION_FAILED' as TypeEnum,
        TeamNotificationConnectionConfigInvalid: 'TEAM_NOTIFICATION_CONNECTION_CONFIG_INVALID' as TypeEnum,
        DataDependencyUnsatisfiable: 'DATA_DEPENDENCY_UNSATISFIABLE' as TypeEnum,
        ReflectionFailed: 'REFLECTION_FAILED' as TypeEnum,
        FileStorageFailed: 'FILE_STORAGE_FAILED' as TypeEnum,
        InvalidFeatureConfiguration: 'INVALID_FEATURE_CONFIGURATION' as TypeEnum,
        RequestProcessingTimeout: 'REQUEST_PROCESSING_TIMEOUT' as TypeEnum
    };
}


