import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { BehaviorSubject, catchError, filter, first, Observable, retry, switchMap, throwError, timer } from 'rxjs';

import { ClientAuthenticationResponse } from '@core/models/clientAuthenticationResponse';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenStorageService } from '@core/services/auth/token-storage.service';
import { TokenService } from '@core/services/auth/token.service';

const TOKEN_HEADER_KEY = 'Authorization';
const MAX_RETRIES = 3; // Max number of retry attempts
const RETRY_DELAY = 1000; // Initial delay of 1 second

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  #authService: AuthService = inject(AuthService);
  #tokenService: TokenService = inject(TokenService);
  #tokenStorageService: TokenStorageService = inject(TokenStorageService);

  #isRefreshing = false;
  #refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string>(null);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = req;
    const token = this.#tokenStorageService.getToken();

    if (token) {
      authReq = this.addTokenHeader(req, token);
    } else if (req.detectContentTypeHeader()?.startsWith('text/plain')) {
      authReq = this.addContentType(req);
    }

    return next.handle(authReq).pipe(
      retry({
        count: MAX_RETRIES,
        delay: (error: HttpErrorResponse, retryCount: number) => {
          if (this.shouldRetry(error)) {
            return timer(this.getRetryDelay(retryCount));
          }
          throw error;
        },
        resetOnSuccess: true
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !authReq.url.includes('auth/token')) {
          return this.handle401Error(authReq, next);
        }
        return throwError(() => error);
      })
    );
  }

  private shouldRetry(error: HttpErrorResponse): boolean {
    // Retry for transient errors like network issues and server errors
    return [500, 502, 503, 504, 408, 429, 0].includes(error.status);
  }

  private getRetryDelay(retryCount: number): number {
    // Exponential backoff delay logic, e.g., 1s, 2s, 4s
    return RETRY_DELAY * Math.pow(2, retryCount); // Escalating delay
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.#isRefreshing) {
      this.#isRefreshing = true;
      this.#refreshTokenSubject.next(null);

      const refreshToken = this.#tokenStorageService.getRefreshToken();
      if (refreshToken) {
        return this.#authService.refreshToken(refreshToken).pipe(
          switchMap((response: ClientAuthenticationResponse) => {
            this.#isRefreshing = false;
            this.#tokenService.setToken(response.access_token);
            this.#refreshTokenSubject.next(response.access_token);
            return next.handle(this.addTokenHeader(request, response.access_token));
          }),
          catchError(error => {
            this.#isRefreshing = false;
            this.#tokenService.clear();
            return throwError(() => error);
          })
        );
      }
    }
    return this.#refreshTokenSubject.pipe(
      filter((token: string) => !!token),
      first(),
      switchMap((token: string) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        [TOKEN_HEADER_KEY]: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
  }

  private addContentType(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });
  }
}
