/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientResource { 
    id: string;
    name?: string;
    abbreviation?: string;
    shortName?: string;
    type?: ClientResource.TypeEnum;
}
export namespace ClientResource {
    export type TypeEnum = 'BED' | 'ICU_BED' | 'LANDING_SITE' | 'DEVICE' | 'EMERGENCY_ROOM_ADULT' | 'EMERGENCY_ROOM_CHILD';
    export const TypeEnum = {
        Bed: 'BED' as TypeEnum,
        IcuBed: 'ICU_BED' as TypeEnum,
        LandingSite: 'LANDING_SITE' as TypeEnum,
        Device: 'DEVICE' as TypeEnum,
        EmergencyRoomAdult: 'EMERGENCY_ROOM_ADULT' as TypeEnum,
        EmergencyRoomChild: 'EMERGENCY_ROOM_CHILD' as TypeEnum
    };
}


