/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientUserGeneratedIssueStatusRecord } from './client-user-generated-issue-status-record';


export interface ClientUserGeneratedIssueDetail { 
    id: string;
    issueId?: string;
    lastStatusRecord?: ClientUserGeneratedIssueStatusRecord;
    lastStatusUpdate?: number;
    status?: ClientUserGeneratedIssueDetail.StatusEnum;
    statusRecords?: Array<ClientUserGeneratedIssueStatusRecord>;
    type?: ClientUserGeneratedIssueDetail.TypeEnum;
}
export namespace ClientUserGeneratedIssueDetail {
    export type StatusEnum = 'OPEN' | 'IN_PROGRESS' | 'AWAITING_RESPONSE' | 'CLOSED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        AwaitingResponse: 'AWAITING_RESPONSE' as StatusEnum,
        Closed: 'CLOSED' as StatusEnum
    };
    export type TypeEnum = 'SUGGESTION' | 'INFORMATION' | 'ERROR';
    export const TypeEnum = {
        Suggestion: 'SUGGESTION' as TypeEnum,
        Information: 'INFORMATION' as TypeEnum,
        Error: 'ERROR' as TypeEnum
    };
}


