/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientIntensiveCareUnitBed { 
    id: string;
    name?: string;
    ageGroup?: ClientIntensiveCareUnitBed.AgeGroupEnum;
    available?: number;
    changeable?: boolean;
    hospitalId?: string;
    information?: string;
    lastStateChange?: number;
    occupied?: number;
    onDuty?: boolean;
    state?: ClientIntensiveCareUnitBed.StateEnum;
    unitId?: string;
}
export namespace ClientIntensiveCareUnitBed {
    export type AgeGroupEnum = 'ADULT' | 'CHILD';
    export const AgeGroupEnum = {
        Adult: 'ADULT' as AgeGroupEnum,
        Child: 'CHILD' as AgeGroupEnum
    };
    export type StateEnum = 'AVAILABLE' | 'OVERLOAD' | 'DEFECT' | 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' | 'NOT_ON_DUTY';
    export const StateEnum = {
        Available: 'AVAILABLE' as StateEnum,
        Overload: 'OVERLOAD' as StateEnum,
        Defect: 'DEFECT' as StateEnum,
        NotAvailableWithoutRedundancy: 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' as StateEnum,
        NotOnDuty: 'NOT_ON_DUTY' as StateEnum
    };
}


