/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WebsocketRescueServiceArea } from './websocket-rescue-service-area';
import { WebsocketDivonoDepartment } from './websocket-divono-department';


export interface ClientDivonoMessage { 
    id?: string;
    updatedAt?: number;
    hospitalId?: string;
    hospitalRescueServiceArea?: WebsocketRescueServiceArea;
    divonoDepartment?: WebsocketDivonoDepartment;
    rescueServiceArea?: WebsocketRescueServiceArea;
    shortName?: string;
    uniqueTransportId?: string;
    uniquePatientId?: string;
    dataSource?: ClientDivonoMessage.DataSourceEnum;
    created?: number;
    estimatedTimeOfArrival?: number;
    uniqueVehicleId?: string;
    rescueVehicleType?: ClientDivonoMessage.RescueVehicleTypeEnum;
    transportAccompanied?: ClientDivonoMessage.TransportAccompaniedEnum;
    age?: number;
    gender?: ClientDivonoMessage.GenderEnum;
    workingDiagnosis?: string;
    infectionStatus?: ClientDivonoMessage.InfectionStatusEnum;
    information?: string;
    astatus?: ClientDivonoMessage.AstatusEnum;
    bstatus?: ClientDivonoMessage.BstatusEnum;
    cstatus?: ClientDivonoMessage.CstatusEnum;
    dstatus?: ClientDivonoMessage.DstatusEnum;
}
export namespace ClientDivonoMessage {
    export type DataSourceEnum = 'CONTROL_CENTER' | 'RESCUE_TEAM';
    export const DataSourceEnum = {
        ControlCenter: 'CONTROL_CENTER' as DataSourceEnum,
        RescueTeam: 'RESCUE_TEAM' as DataSourceEnum
    };
    export type RescueVehicleTypeEnum = 'NEF' | 'ITW' | 'RTW' | 'NKTW' | 'KTW' | 'RTH' | 'ITH' | 'GRTW' | 'UNKNOWN';
    export const RescueVehicleTypeEnum = {
        Nef: 'NEF' as RescueVehicleTypeEnum,
        Itw: 'ITW' as RescueVehicleTypeEnum,
        Rtw: 'RTW' as RescueVehicleTypeEnum,
        Nktw: 'NKTW' as RescueVehicleTypeEnum,
        Ktw: 'KTW' as RescueVehicleTypeEnum,
        Rth: 'RTH' as RescueVehicleTypeEnum,
        Ith: 'ITH' as RescueVehicleTypeEnum,
        Grtw: 'GRTW' as RescueVehicleTypeEnum,
        Unknown: 'UNKNOWN' as RescueVehicleTypeEnum
    };
    export type TransportAccompaniedEnum = 'UNKNOWN' | 'NO' | 'EMERGENCY_DOCTOR';
    export const TransportAccompaniedEnum = {
        Unknown: 'UNKNOWN' as TransportAccompaniedEnum,
        No: 'NO' as TransportAccompaniedEnum,
        EmergencyDoctor: 'EMERGENCY_DOCTOR' as TransportAccompaniedEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER' | 'UNKNOWN';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum,
        Unknown: 'UNKNOWN' as GenderEnum
    };
    export type InfectionStatusEnum = 'INCONSPICUOUS' | 'RESTRICTED' | 'DECOMPENSATED';
    export const InfectionStatusEnum = {
        Inconspicuous: 'INCONSPICUOUS' as InfectionStatusEnum,
        Restricted: 'RESTRICTED' as InfectionStatusEnum,
        Decompensated: 'DECOMPENSATED' as InfectionStatusEnum
    };
    export type AstatusEnum = 'INCONSPICUOUS' | 'RESTRICTED' | 'DECOMPENSATED';
    export const AstatusEnum = {
        Inconspicuous: 'INCONSPICUOUS' as AstatusEnum,
        Restricted: 'RESTRICTED' as AstatusEnum,
        Decompensated: 'DECOMPENSATED' as AstatusEnum
    };
    export type BstatusEnum = 'INCONSPICUOUS' | 'RESTRICTED' | 'DECOMPENSATED';
    export const BstatusEnum = {
        Inconspicuous: 'INCONSPICUOUS' as BstatusEnum,
        Restricted: 'RESTRICTED' as BstatusEnum,
        Decompensated: 'DECOMPENSATED' as BstatusEnum
    };
    export type CstatusEnum = 'INCONSPICUOUS' | 'RESTRICTED' | 'DECOMPENSATED';
    export const CstatusEnum = {
        Inconspicuous: 'INCONSPICUOUS' as CstatusEnum,
        Restricted: 'RESTRICTED' as CstatusEnum,
        Decompensated: 'DECOMPENSATED' as CstatusEnum
    };
    export type DstatusEnum = 'INCONSPICUOUS' | 'RESTRICTED' | 'DECOMPENSATED';
    export const DstatusEnum = {
        Inconspicuous: 'INCONSPICUOUS' as DstatusEnum,
        Restricted: 'RESTRICTED' as DstatusEnum,
        Decompensated: 'DECOMPENSATED' as DstatusEnum
    };
}


