/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientUserGeneratedIssueStatusChangeRequest { 
    /**
     * ID of the user generated issue
     */
    issueId: string;
    /**
     * message on why this status change has happened
     */
    message: string;
    /**
     * new status of the user generated issue
     */
    newStatus: ClientUserGeneratedIssueStatusChangeRequest.NewStatusEnum;
}
export namespace ClientUserGeneratedIssueStatusChangeRequest {
    export type NewStatusEnum = 'OPEN' | 'IN_PROGRESS' | 'AWAITING_RESPONSE' | 'CLOSED';
    export const NewStatusEnum = {
        Open: 'OPEN' as NewStatusEnum,
        InProgress: 'IN_PROGRESS' as NewStatusEnum,
        AwaitingResponse: 'AWAITING_RESPONSE' as NewStatusEnum,
        Closed: 'CLOSED' as NewStatusEnum
    };
}


