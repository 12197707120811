import { Routes } from '@angular/router';

import { AdminGuard } from '@core/guards/admin.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { FeatureGuard } from '@core/guards/feature.guard';
import { FeatureFlags } from '@core/services/feature-flag.service';
import { OrganizationRoles } from '@core/services/role.service';

const rootRoutes: Routes = [
  {
    path: 'support/token/:mailToken',
    loadComponent: () =>
      import('@shared/components/issue-detail-token/issue-detail-token.component').then(
        m => m.IssueDetailTokenComponent
      )
  },
  {
    path: 'dashboard/:dashboardToken',
    loadComponent: () =>
      import('@routine/components/status-dashboard/status-dashboard.component').then(m => m.StatusDashboardComponent)
  },
  {
    path: 'tableau/:dashboardToken',
    loadComponent: () =>
      import('@routine/components/tableau-dashboard/tableau-dashboard.component').then(m => m.TableauDashboardComponent)
  },
  {
    path: 'divono/:dashboardToken',
    loadComponent: () =>
      import('@divono/components/divono-dashboard/divono-dashboard.component').then(m => m.DivonoDashboardComponent)
  },
  {
    path: 'manv/lagebild/:id',
    loadComponent: () => import('@manv/components/lagebild/lagebild.component').then(m => m.LagebildComponent),
    canActivate: [AuthGuard, FeatureGuard],
    data: {
      feature: FeatureFlags.MANV
    }
  },
  {
    path: 'admin',
    loadComponent: () => import('@app/app.component').then(m => m.AppComponent),
    loadChildren: () => import('@admin/admin-routes'),
    canActivate: [AuthGuard],
    canMatch: [AdminGuard],
    data: {
      breadcrumb: 'Admin',
      roles: [OrganizationRoles.ADMIN]
    }
  },
  {
    path: 'divono',
    loadComponent: () => import('@app/app.component').then(m => m.AppComponent),
    loadChildren: () => import('@divono/divono-routes'),
    canActivate: [AuthGuard, FeatureGuard],
    data: {
      breadcrumb: 'DIVONO',
      feature: FeatureFlags.DIVONO,
      roles: [OrganizationRoles.ADMIN, OrganizationRoles.CONTROL_CENTER, OrganizationRoles.HOSPITAL]
    }
  },
  {
    path: 'manv',
    loadComponent: () => import('@app/app.component').then(m => m.AppComponent),
    loadChildren: () => import('@manv/manv-routes'),
    canActivate: [AuthGuard, FeatureGuard],
    data: {
      breadcrumb: 'MANV',
      feature: FeatureFlags.MANV,
      roles: [
        OrganizationRoles.ADMIN,
        OrganizationRoles.AUTHORITY,
        OrganizationRoles.CONTROL_CENTER,
        OrganizationRoles.HOSPITAL,
        OrganizationRoles.MINISTRY,
        OrganizationRoles.RESCUE_SERVICE,
        OrganizationRoles.SECTION_MANAGEMENT_HEALTH
      ]
    }
  },
  {
    path: 'nef',
    loadComponent: () => import('@app/app.component').then(m => m.AppComponent),
    loadChildren: () => import('@nef/nef-routes'),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'NEF',
      roles: [OrganizationRoles.ADMIN, OrganizationRoles.CONTROL_CENTER]
    }
  },
  {
    path: 'routine',
    loadComponent: () => import('@app/app.component').then(m => m.AppComponent),
    loadChildren: () => import('@routine/routine-routes'),
    data: {
      breadcrumb: 'Routine'
    }
  },
  {
    path: '',
    loadComponent: () => import('@app/app.component').then(m => m.AppComponent),
    loadChildren: () => import('@shared/shared-routes')
  },
  {
    path: '**',
    redirectTo: '/aktuelles'
  }
];

export default rootRoutes;
