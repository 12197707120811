import { computed, effect, EffectRef, inject, Injectable, Signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';

import { interval } from 'rxjs';

import { NotificationService } from '@core/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private swUpdate: SwUpdate = inject(SwUpdate);
  private notificationService: NotificationService = inject(NotificationService);

  private swEnabled = computed(() => this.swUpdate.isEnabled);

  private oneHourInterval: Signal<number> = toSignal(interval(60 * 60 * 1000));

  private checkUpdateEffect: EffectRef = effect(async () => {
    if (this.swEnabled()) {
      this.oneHourInterval();
      await untracked(async () => {
        try {
          await this.swUpdate.checkForUpdate();
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  });

  private versionUpdates: Signal<VersionEvent> = toSignal(this.swUpdate.versionUpdates);

  private versionUpdateEffect: EffectRef = effect(() => {
    if (this.swEnabled() && this.versionUpdates() && this.versionUpdates().type === 'VERSION_READY') {
      untracked(() =>
        this.notificationService.add({
          key: 'update-toast',
          sticky: true,
          closable: false,
          severity: 'info',
          summary: 'Update verfügbar',
          detail: 'Bitte die Seite neu laden'
        })
      );
    }
  });

  private unrecoverable: Signal<UnrecoverableStateEvent> = toSignal(this.swUpdate.unrecoverable);

  private unrecoverableEffect: EffectRef = effect(() => {
    if (this.swEnabled() && this.unrecoverable()) {
      untracked(() => window.location.reload());
    }
  });
}
