import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable, untracked } from '@angular/core';

import { EventData } from '@core/models/eventData';
import { ErrorService } from '@core/services/error.service';
import { EventBusService } from '@core/services/event-bus.service';
import { LoggingService } from '@core/services/logging.service';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  #errorService = inject(ErrorService);
  #loggingService = inject(LoggingService);
  #notificationService = inject(NotificationService);
  #eventBusService = inject(EventBusService);

  handleError(error: Error | HttpErrorResponse): void {
    untracked(() => {
      const errorMessage = this.#errorService.getErrorMessage(error);
      this.#loggingService.logError(errorMessage);

      if (error instanceof HttpErrorResponse) {
        this.handleHttpError(error);
      } else {
        this.handleClientError(error);
      }
    });
  }

  private handleHttpError(error: HttpErrorResponse): void {
    // Handle HTTP-specific errors
    const errorMessage = this.#errorService.getErrorMessage(error);

    switch (error.status) {
      case 401:
        // Unauthorized - trigger logout
        this.#eventBusService.emit(new EventData('logout', null));
        break;
      case 403:
        // Forbidden
        this.#notificationService.notify('error', 'Zugriff verweigert', errorMessage.message);
        break;
      case 500:
        // Internal Server Error
        this.#notificationService.notify('error', 'Server Fehler', 'Ein Serverfehler ist aufgetreten.');
        break;
      case 503:
        // Service Unavailable
        this.#notificationService.notify(
          'error',
          'Service nicht verfügbar',
          'Der Dienst ist vorübergehend nicht verfügbar.'
        );
        break;
      default:
        // Handle other HTTP errors
        this.#notificationService.notify('error', 'Fehler', errorMessage.message);
        break;
    }
  }

  private handleClientError(error: Error): void {
    // Handle non-HTTP errors such as Angular or runtime errors
    const errorMessage = this.#errorService.getErrorMessage(error);

    // Log additional context for debugging
    this.#loggingService.logError(errorMessage);

    if (error instanceof TypeError) {
      this.#notificationService.notify('error', 'Typfehler', 'Ein Typfehler ist aufgetreten.');
    } else if (error instanceof SyntaxError) {
      this.#notificationService.notify('error', 'Syntaxfehler', 'Ein Syntaxfehler ist aufgetreten.');
    } else if (error instanceof ReferenceError) {
      this.#notificationService.notify('error', 'Referenzfehler', 'Ein Referenzfehler ist aufgetreten.');
    } else {
      // Fallback for any unknown errors
      this.#notificationService.notify(
        'error',
        'Unbekannter Fehler',
        errorMessage.message || 'Ein unbekannter Fehler ist aufgetreten.'
      );
    }
  }
}
