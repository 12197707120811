/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientPatient { 
    id?: string;
    eventId?: string;
    name?: string;
    ageGroup?: ClientPatient.AgeGroupEnum;
    triageClassification?: ClientPatient.TriageClassificationEnum;
    state?: ClientPatient.StateEnum;
    gravidity?: boolean;
    created?: number;
    planned?: number;
    departure?: number;
    arrival?: number;
    vehicleName?: string;
    diagnosisId?: string;
    specialityId?: string;
    hospitalId?: string;
    transportMode?: string;
    remarks?: string;
    createdBy?: string;
    contaminated?: boolean;
    priority?: boolean;
    location?: string;
}
export namespace ClientPatient {
    export type AgeGroupEnum = 'SGL' | 'KLE' | 'SCH' | 'ERW';
    export const AgeGroupEnum = {
        Sgl: 'SGL' as AgeGroupEnum,
        Kle: 'KLE' as AgeGroupEnum,
        Sch: 'SCH' as AgeGroupEnum,
        Erw: 'ERW' as AgeGroupEnum
    };
    export type TriageClassificationEnum = 'SK1' | 'SK2' | 'SK3' | 'SK4' | 'EX' | 'B';
    export const TriageClassificationEnum = {
        Sk1: 'SK1' as TriageClassificationEnum,
        Sk2: 'SK2' as TriageClassificationEnum,
        Sk3: 'SK3' as TriageClassificationEnum,
        Sk4: 'SK4' as TriageClassificationEnum,
        Ex: 'EX' as TriageClassificationEnum,
        B: 'B' as TriageClassificationEnum
    };
    export type StateEnum = 'ON_SITE' | 'ON_SITE_PLANNED' | 'TRANSPORT' | 'IN_HOSPITAL' | 'CANCEL' | 'NO_TRANSPORT';
    export const StateEnum = {
        OnSite: 'ON_SITE' as StateEnum,
        OnSitePlanned: 'ON_SITE_PLANNED' as StateEnum,
        Transport: 'TRANSPORT' as StateEnum,
        InHospital: 'IN_HOSPITAL' as StateEnum,
        Cancel: 'CANCEL' as StateEnum,
        NoTransport: 'NO_TRANSPORT' as StateEnum
    };
}


