/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientSuggestionCreateRequest { 
    type: ClientSuggestionCreateRequest.TypeEnum;
    name: string;
    email: string;
    message: string;
}
export namespace ClientSuggestionCreateRequest {
    export type TypeEnum = 'SUGGESTION' | 'INFORMATION' | 'ERROR';
    export const TypeEnum = {
        Suggestion: 'SUGGESTION' as TypeEnum,
        Information: 'INFORMATION' as TypeEnum,
        Error: 'ERROR' as TypeEnum
    };
}


