/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientUserGeneratedIssueCreateRequest { 
    type: ClientUserGeneratedIssueCreateRequest.TypeEnum;
    hidden?: boolean;
    name?: string;
    email?: string;
    message: string;
}
export namespace ClientUserGeneratedIssueCreateRequest {
    export type TypeEnum = 'SUGGESTION' | 'INFORMATION' | 'ERROR';
    export const TypeEnum = {
        Suggestion: 'SUGGESTION' as TypeEnum,
        Information: 'INFORMATION' as TypeEnum,
        Error: 'ERROR' as TypeEnum
    };
}


