(async () => {
  // Polyfill Promise before loading other required packages
  if (typeof Promise === 'undefined') {
    await import('core-js/es/promise');
  }

  const polyfills = [];

  // Check for structuredClone
  if (typeof window.structuredClone !== 'function') {
    polyfills.push(
      import('@ungap/structured-clone').then(({ default: structuredClone }) => {
        window.structuredClone = structuredClone;
      })
    );
  }

  // Check for URL
  if (typeof window.URL !== 'function') {
    polyfills.push(
      import('url-shim').then(module => {
        window.URL = module.URL;
      })
    );
  }

  // Check for ResizeObserver
  if (typeof window.ResizeObserver !== 'function') {
    polyfills.push(
      import('@juggle/resize-observer').then(module => {
        window.ResizeObserver = module.ResizeObserver;
      })
    );
  }

  // Load all necessary polyfills
  await Promise.all(polyfills);
})();
