/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientDutyHours } from './client-duty-hours';


export interface ClientDevice { 
    id: string;
    name?: string;
    abbreviation?: string;
    changeable?: boolean;
    departmentId?: string;
    dutyHours?: ClientDutyHours;
    hospitalId?: string;
    information?: string;
    lastStateChange?: number;
    location?: string;
    onDuty?: boolean;
    phoneNumber?: string;
    resourceName?: string;
    state?: ClientDevice.StateEnum;
}
export namespace ClientDevice {
    export type StateEnum = 'AVAILABLE' | 'OVERLOAD' | 'DEFECT' | 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' | 'NOT_ON_DUTY';
    export const StateEnum = {
        Available: 'AVAILABLE' as StateEnum,
        Overload: 'OVERLOAD' as StateEnum,
        Defect: 'DEFECT' as StateEnum,
        NotAvailableWithoutRedundancy: 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' as StateEnum,
        NotOnDuty: 'NOT_ON_DUTY' as StateEnum
    };
}


