/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientDepartmentBedChangeStateRequest { 
    departmentBedId: string;
    state: ClientDepartmentBedChangeStateRequest.StateEnum;
    available: number;
    occupied: number;
    lateRegistration: boolean;
    submitter: string;
}
export namespace ClientDepartmentBedChangeStateRequest {
    export type StateEnum = 'AVAILABLE' | 'OVERLOAD' | 'DEFECT' | 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' | 'NOT_ON_DUTY';
    export const StateEnum = {
        Available: 'AVAILABLE' as StateEnum,
        Overload: 'OVERLOAD' as StateEnum,
        Defect: 'DEFECT' as StateEnum,
        NotAvailableWithoutRedundancy: 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' as StateEnum,
        NotOnDuty: 'NOT_ON_DUTY' as StateEnum
    };
}


