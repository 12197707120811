/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientUser } from './client-user';


export interface ClientUserGeneratedIssueStatusRecord { 
    id: string;
    created?: number;
    initiator?: ClientUser;
    message?: string;
    status?: ClientUserGeneratedIssueStatusRecord.StatusEnum;
}
export namespace ClientUserGeneratedIssueStatusRecord {
    export type StatusEnum = 'OPEN' | 'IN_PROGRESS' | 'AWAITING_RESPONSE' | 'CLOSED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        AwaitingResponse: 'AWAITING_RESPONSE' as StatusEnum,
        Closed: 'CLOSED' as StatusEnum
    };
}


