/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ClientSearchRequest } from '../model/client-search-request';
// @ts-ignore
import { ClientSearchResponse } from '../model/client-search-response';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ParticipantsSearchService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Return all hospitals with distance, duration and linearDistance that meets the search criteria 🔒 jwt
     * @param latitude 
     * @param longitude 
     * @param specialityId 
     * @param resourceId 
     * @param ageGroup 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAvailableDepartmentCapacityByDistance(latitude: number, longitude: number, specialityId: string, resourceId: string, ageGroup: 'ADULT' | 'CHILD', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<Array<ClientSearchResponse>>;
    public getAllAvailableDepartmentCapacityByDistance(latitude: number, longitude: number, specialityId: string, resourceId: string, ageGroup: 'ADULT' | 'CHILD', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ClientSearchResponse>>>;
    public getAllAvailableDepartmentCapacityByDistance(latitude: number, longitude: number, specialityId: string, resourceId: string, ageGroup: 'ADULT' | 'CHILD', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ClientSearchResponse>>>;
    public getAllAvailableDepartmentCapacityByDistance(latitude: number, longitude: number, specialityId: string, resourceId: string, ageGroup: 'ADULT' | 'CHILD', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling getAllAvailableDepartmentCapacityByDistance.');
        }
        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling getAllAvailableDepartmentCapacityByDistance.');
        }
        if (specialityId === null || specialityId === undefined) {
            throw new Error('Required parameter specialityId was null or undefined when calling getAllAvailableDepartmentCapacityByDistance.');
        }
        if (resourceId === null || resourceId === undefined) {
            throw new Error('Required parameter resourceId was null or undefined when calling getAllAvailableDepartmentCapacityByDistance.');
        }
        if (ageGroup === null || ageGroup === undefined) {
            throw new Error('Required parameter ageGroup was null or undefined when calling getAllAvailableDepartmentCapacityByDistance.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (latitude !== undefined && latitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>latitude, 'latitude');
        }
        if (longitude !== undefined && longitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>longitude, 'longitude');
        }
        if (specialityId !== undefined && specialityId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>specialityId, 'specialityId');
        }
        if (resourceId !== undefined && resourceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resourceId, 'resourceId');
        }
        if (ageGroup !== undefined && ageGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ageGroup, 'ageGroup');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/search/departmentCapacity`;
        return this.httpClient.request<Array<ClientSearchResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return all hospitals with distance, duration and linearDistance that meets the search criteria 🔒 jwt
     * @param latitude 
     * @param longitude 
     * @param resourceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAvailableDeviceCapacityByDistance(latitude: number, longitude: number, resourceId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<Array<ClientSearchResponse>>;
    public getAllAvailableDeviceCapacityByDistance(latitude: number, longitude: number, resourceId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ClientSearchResponse>>>;
    public getAllAvailableDeviceCapacityByDistance(latitude: number, longitude: number, resourceId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ClientSearchResponse>>>;
    public getAllAvailableDeviceCapacityByDistance(latitude: number, longitude: number, resourceId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling getAllAvailableDeviceCapacityByDistance.');
        }
        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling getAllAvailableDeviceCapacityByDistance.');
        }
        if (resourceId === null || resourceId === undefined) {
            throw new Error('Required parameter resourceId was null or undefined when calling getAllAvailableDeviceCapacityByDistance.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (latitude !== undefined && latitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>latitude, 'latitude');
        }
        if (longitude !== undefined && longitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>longitude, 'longitude');
        }
        if (resourceId !== undefined && resourceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resourceId, 'resourceId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/search/deviceCapacity`;
        return this.httpClient.request<Array<ClientSearchResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return all hospitals with distance, duration and linearDistance that meets the search criteria 🔒 jwt
     * @param latitude 
     * @param longitude 
     * @param emergencyCareNeeded 
     * @param diagnosisId 
     * @param ageGroup 
     * @param childAge 
     * @param landingSiteNeeded 
     * @param resourceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllAvailableIntensiveCareUnitCapacityByDistance(latitude: number, longitude: number, emergencyCareNeeded: boolean, diagnosisId: string, ageGroup: 'ADULT' | 'CHILD', childAge: number, landingSiteNeeded: boolean, resourceId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<Array<ClientSearchResponse>>;
    public getAllAvailableIntensiveCareUnitCapacityByDistance(latitude: number, longitude: number, emergencyCareNeeded: boolean, diagnosisId: string, ageGroup: 'ADULT' | 'CHILD', childAge: number, landingSiteNeeded: boolean, resourceId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ClientSearchResponse>>>;
    public getAllAvailableIntensiveCareUnitCapacityByDistance(latitude: number, longitude: number, emergencyCareNeeded: boolean, diagnosisId: string, ageGroup: 'ADULT' | 'CHILD', childAge: number, landingSiteNeeded: boolean, resourceId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ClientSearchResponse>>>;
    public getAllAvailableIntensiveCareUnitCapacityByDistance(latitude: number, longitude: number, emergencyCareNeeded: boolean, diagnosisId: string, ageGroup: 'ADULT' | 'CHILD', childAge: number, landingSiteNeeded: boolean, resourceId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }
        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }
        if (emergencyCareNeeded === null || emergencyCareNeeded === undefined) {
            throw new Error('Required parameter emergencyCareNeeded was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }
        if (diagnosisId === null || diagnosisId === undefined) {
            throw new Error('Required parameter diagnosisId was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }
        if (ageGroup === null || ageGroup === undefined) {
            throw new Error('Required parameter ageGroup was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }
        if (childAge === null || childAge === undefined) {
            throw new Error('Required parameter childAge was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }
        if (landingSiteNeeded === null || landingSiteNeeded === undefined) {
            throw new Error('Required parameter landingSiteNeeded was null or undefined when calling getAllAvailableIntensiveCareUnitCapacityByDistance.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (latitude !== undefined && latitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>latitude, 'latitude');
        }
        if (longitude !== undefined && longitude !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>longitude, 'longitude');
        }
        if (emergencyCareNeeded !== undefined && emergencyCareNeeded !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>emergencyCareNeeded, 'emergencyCareNeeded');
        }
        if (diagnosisId !== undefined && diagnosisId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>diagnosisId, 'diagnosisId');
        }
        if (ageGroup !== undefined && ageGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ageGroup, 'ageGroup');
        }
        if (childAge !== undefined && childAge !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>childAge, 'childAge');
        }
        if (resourceId !== undefined && resourceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>resourceId, 'resourceId');
        }
        if (landingSiteNeeded !== undefined && landingSiteNeeded !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>landingSiteNeeded, 'landingSiteNeeded');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/search/intensiveCareUnitCapacity`;
        return this.httpClient.request<Array<ClientSearchResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search 🔒 jwt
     * @param clientSearchRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchWithCriteria(clientSearchRequest: ClientSearchRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<Array<ClientSearchResponse>>;
    public searchWithCriteria(clientSearchRequest: ClientSearchRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ClientSearchResponse>>>;
    public searchWithCriteria(clientSearchRequest: ClientSearchRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ClientSearchResponse>>>;
    public searchWithCriteria(clientSearchRequest: ClientSearchRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (clientSearchRequest === null || clientSearchRequest === undefined) {
            throw new Error('Required parameter clientSearchRequest was null or undefined when calling searchWithCriteria.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (bearerAuth) required
        localVarCredential = this.configuration.lookupCredential('bearerAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/search`;
        return this.httpClient.request<Array<ClientSearchResponse>>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clientSearchRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
