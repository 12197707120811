/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientStatus { 
    id: string;
    available?: number;
    hospitalId?: string;
    lastStateChange?: number;
    occupied?: number;
    onDuty?: boolean;
    referenceId?: string;
    reset?: number;
    state?: ClientStatus.StateEnum;
    type?: ClientStatus.TypeEnum;
}
export namespace ClientStatus {
    export type StateEnum = 'AVAILABLE' | 'OVERLOAD' | 'DEFECT' | 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' | 'NOT_ON_DUTY';
    export const StateEnum = {
        Available: 'AVAILABLE' as StateEnum,
        Overload: 'OVERLOAD' as StateEnum,
        Defect: 'DEFECT' as StateEnum,
        NotAvailableWithoutRedundancy: 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' as StateEnum,
        NotOnDuty: 'NOT_ON_DUTY' as StateEnum
    };
    export type TypeEnum = 'KL' | 'AV' | 'IN' | 'AB' | 'TN';
    export const TypeEnum = {
        Kl: 'KL' as TypeEnum,
        Av: 'AV' as TypeEnum,
        In: 'IN' as TypeEnum,
        Ab: 'AB' as TypeEnum,
        Tn: 'TN' as TypeEnum
    };
}


