/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientSituation { 
    id: string;
    name?: string;
    ageGroup?: ClientSituation.AgeGroupEnum;
    count?: number;
    information?: string;
    total?: number;
    type?: ClientSituation.TypeEnum;
}
export namespace ClientSituation {
    export type AgeGroupEnum = 'ADULT' | 'CHILD';
    export const AgeGroupEnum = {
        Adult: 'ADULT' as AgeGroupEnum,
        Child: 'CHILD' as AgeGroupEnum
    };
    export type TypeEnum = 'KL' | 'AV' | 'IN' | 'AB' | 'TN';
    export const TypeEnum = {
        Kl: 'KL' as TypeEnum,
        Av: 'AV' as TypeEnum,
        In: 'IN' as TypeEnum,
        Ab: 'AB' as TypeEnum,
        Tn: 'TN' as TypeEnum
    };
}


