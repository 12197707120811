import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError, catchError } from 'rxjs';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  private router: Router = inject(Router);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token flow if required
          return throwError(() => error);
        } else if (error.status === 403) {
          if (RegExp(/^.*\/token\/.*$/).exec(error.url)) {
            // Redirect unauthorized token requests to landing page
            this.router.navigateByUrl('/');
          }
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }
}
