import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, finalize } from 'rxjs';

import { LoadingService } from '@core/services/loading.service';

import { environment } from '@environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests: number = 0;
  urlRegex: RegExp = new RegExp(environment.backend_url + '/(.*)');
  uuidRegex: RegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.activeRequests === 0) {
      this.loadingService.startLoading();
    }

    this.activeRequests++;
    const path: string = RegExp(this.urlRegex).exec(request.url)[1];
    const topic: string = path.split('/')[0];
    this.loadingService.increaseTopic(topic);
    const uuid: RegExpMatchArray = RegExp(this.uuidRegex).exec(path);
    if (uuid) {
      this.loadingService.increaseTopic(uuid[0]);
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        this.loadingService.decreaseTopic(topic);
        if (uuid) {
          this.loadingService.decreaseTopic(uuid[0]);
        }
        if (this.activeRequests === 0) {
          this.loadingService.stopLoading();
        }
      })
    );
  }
}
