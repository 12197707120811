/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DutyOverride } from './duty-override';
import { Capabilities } from './capabilities';
import { ManvContactDetailMessage } from './manv-contact-detail-message';


export interface ClientHospitalData { 
    id?: string;
    created?: number;
    hospitalId?: string;
    contact?: ManvContactDetailMessage;
    initial?: Array<number>;
    follow?: Array<number>;
    current?: Array<number>;
    level?: number;
    followUpConfirmed?: boolean;
    followUpRequested?: number;
    capabilities?: Capabilities;
    ptAge?: ClientHospitalData.PtAgeEnum;
    information?: string;
    dutyOverrides?: Array<DutyOverride>;
}
export namespace ClientHospitalData {
    export type PtAgeEnum = 'SGL' | 'KLE' | 'SCH' | 'ERW';
    export const PtAgeEnum = {
        Sgl: 'SGL' as PtAgeEnum,
        Kle: 'KLE' as PtAgeEnum,
        Sch: 'SCH' as PtAgeEnum,
        Erw: 'ERW' as PtAgeEnum
    };
}


