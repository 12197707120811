/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Triage { 
    ageGroup?: Triage.AgeGroupEnum;
    sk?: Array<number>;
}
export namespace Triage {
    export type AgeGroupEnum = 'ADULT' | 'CHILD';
    export const AgeGroupEnum = {
        Adult: 'ADULT' as AgeGroupEnum,
        Child: 'CHILD' as AgeGroupEnum
    };
}


