/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientAddress } from './client-address';


export interface ClientHospital { 
    id: string;
    name?: string;
    abbreviation?: string;
    address?: ClientAddress;
    changeable?: boolean;
    idKLN?: number;
    lastStateChange?: number;
    onDuty?: boolean;
    phoneNumber?: string;
    selected?: boolean;
    shortName?: string;
    state?: ClientHospital.StateEnum;
    traumaCenterLevel?: string;
    www?: string;
}
export namespace ClientHospital {
    export type StateEnum = 'AVAILABLE' | 'OVERLOAD' | 'DEFECT' | 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' | 'NOT_ON_DUTY';
    export const StateEnum = {
        Available: 'AVAILABLE' as StateEnum,
        Overload: 'OVERLOAD' as StateEnum,
        Defect: 'DEFECT' as StateEnum,
        NotAvailableWithoutRedundancy: 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' as StateEnum,
        NotOnDuty: 'NOT_ON_DUTY' as StateEnum
    };
}


