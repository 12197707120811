/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ClientDutyHoursEntry { 
    id: string;
    fromTime?: number;
    toTime?: number;
    weekday?: ClientDutyHoursEntry.WeekdayEnum;
}
export namespace ClientDutyHoursEntry {
    export type WeekdayEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const WeekdayEnum = {
        Monday: 'MONDAY' as WeekdayEnum,
        Tuesday: 'TUESDAY' as WeekdayEnum,
        Wednesday: 'WEDNESDAY' as WeekdayEnum,
        Thursday: 'THURSDAY' as WeekdayEnum,
        Friday: 'FRIDAY' as WeekdayEnum,
        Saturday: 'SATURDAY' as WeekdayEnum,
        Sunday: 'SUNDAY' as WeekdayEnum
    };
}


