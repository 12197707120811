/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationAllowedInetAddressesInner } from './organization-allowed-inet-addresses-inner';


export interface Organization { 
    id?: string;
    created?: number;
    name?: string;
    shortName?: string;
    type?: Organization.TypeEnum;
    allowedInetAddresses?: Set<OrganizationAllowedInetAddressesInner>;
}
export namespace Organization {
    export type TypeEnum = 'HOSPITAL' | 'CONTROL_CENTER' | 'AUTHORITY' | 'ADMIN' | 'MINISTRY' | 'INSTITUTION' | 'EXTERNAL' | 'RESCUE_SERVICE' | 'SECTION_MANAGEMENT_HEALTH';
    export const TypeEnum = {
        Hospital: 'HOSPITAL' as TypeEnum,
        ControlCenter: 'CONTROL_CENTER' as TypeEnum,
        Authority: 'AUTHORITY' as TypeEnum,
        Admin: 'ADMIN' as TypeEnum,
        Ministry: 'MINISTRY' as TypeEnum,
        Institution: 'INSTITUTION' as TypeEnum,
        External: 'EXTERNAL' as TypeEnum,
        RescueService: 'RESCUE_SERVICE' as TypeEnum,
        SectionManagementHealth: 'SECTION_MANAGEMENT_HEALTH' as TypeEnum
    };
}


