<p-toast [preventOpenDuplicates]="true"></p-toast>

<p-toast [baseZIndex]="5000" [preventOpenDuplicates]="true" key="update-toast" position="bottom-center">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column flex-1">
      <div class="text-center">
        <i class="pi pi-exclamation-circle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div style="display: grid; gap: 1rem">
        <button
          class="p-button-success"
          (click)="window.location.reload()"
          (keyup.enter)="window.location.reload()"
          tabindex="0"
          style="width: 100%"
          type="button"
          pButton
          label="Update"
          content="Update"></button>
      </div>
    </div>
  </ng-template>
</p-toast>

<!-- Information -->
<p-toast [style]="{ width: '25rem' }" [preventOpenDuplicates]="true" key="information">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-start flex-1 bg-blue-500 p-4">
      <i class="pi pi-exclamation-circle text-white text-2xl mr-3"></i>
      <div>
        <span class="text-xl font-medium text-white">{{ message.summary }}</span>
        <p class="text-white mt-3">{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

<!-- Fehler -->
<p-toast [style]="{ width: '25rem' }" [preventOpenDuplicates]="true" key="error">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-start flex-1 bg-red-500 p-4">
      <i class="pi pi-ban text-white text-2xl mr-3"></i>
      <div>
        <span class="text-xl font-medium text-white">{{ message.summary }}</span>
        <p class="text-white mt-3">{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

<!-- Erfolg -->
<p-toast [style]="{ width: '25rem' }" [preventOpenDuplicates]="true" key="success">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-start flex-1 bg-green-500 p-4">
      <i class="pi pi-check-circle text-white text-2xl mr-3"></i>
      <div>
        <span class="text-xl font-medium text-white">{{ message.summary }}</span>
        <p class="text-white mt-3">{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

<router-outlet></router-outlet>
