/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CapabilityItem { 
    referenceId?: string;
    type?: CapabilityItem.TypeEnum;
    ids?: Array<string>;
}
export namespace CapabilityItem {
    export type TypeEnum = 'KL' | 'AV' | 'IN' | 'AB' | 'TN';
    export const TypeEnum = {
        Kl: 'KL' as TypeEnum,
        Av: 'AV' as TypeEnum,
        In: 'IN' as TypeEnum,
        Ab: 'AB' as TypeEnum,
        Tn: 'TN' as TypeEnum
    };
}


