/**
 * development API of DENIT Platform
 *
 * Contact: dominik.schnier@iese.fraunhofer.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ControlCenter } from './control-center';
import { Organization } from './organization';
import { TraumaCenterLevel } from './trauma-center-level';
import { AddressListEntry } from './address-list-entry';
import { HospitalOwner } from './hospital-owner';
import { RescueServiceArea } from './rescue-service-area';


export interface Hospital { 
    id?: string;
    created?: number;
    state?: Hospital.StateEnum;
    lastStateChange?: number;
    idKLN?: number;
    name?: string;
    shortName?: string;
    abbreviation?: string;
    addresses?: Set<AddressListEntry>;
    phoneNumber?: string;
    www?: string;
    hospitalOwner?: HospitalOwner;
    organization?: Organization;
    supplyLevel?: Hospital.SupplyLevelEnum;
    supplyArea?: Hospital.SupplyAreaEnum;
    rescueServiceArea?: RescueServiceArea;
    controlCenter?: ControlCenter;
    traumaCenterLevel?: TraumaCenterLevel;
}
export namespace Hospital {
    export type StateEnum = 'AVAILABLE' | 'OVERLOAD' | 'DEFECT' | 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' | 'NOT_ON_DUTY';
    export const StateEnum = {
        Available: 'AVAILABLE' as StateEnum,
        Overload: 'OVERLOAD' as StateEnum,
        Defect: 'DEFECT' as StateEnum,
        NotAvailableWithoutRedundancy: 'NOT_AVAILABLE_WITHOUT_REDUNDANCY' as StateEnum,
        NotOnDuty: 'NOT_ON_DUTY' as StateEnum
    };
    export type SupplyLevelEnum = 'FACHKRANKENHAUS' | 'GRUNDVERSORGUNG' | 'MAXIMALVERSORGUNG' | 'REGELVERSORGUNG' | 'SCHWERPUNKTVERSORGUNG';
    export const SupplyLevelEnum = {
        Fachkrankenhaus: 'FACHKRANKENHAUS' as SupplyLevelEnum,
        Grundversorgung: 'GRUNDVERSORGUNG' as SupplyLevelEnum,
        Maximalversorgung: 'MAXIMALVERSORGUNG' as SupplyLevelEnum,
        Regelversorgung: 'REGELVERSORGUNG' as SupplyLevelEnum,
        Schwerpunktversorgung: 'SCHWERPUNKTVERSORGUNG' as SupplyLevelEnum
    };
    export type SupplyAreaEnum = 'MITTELRHEIN_WESTERWALD' | 'RHEINHESSEN_NAHE' | 'RHEINPFALZ' | 'TRIER' | 'WESTPFALZ';
    export const SupplyAreaEnum = {
        MittelrheinWesterwald: 'MITTELRHEIN_WESTERWALD' as SupplyAreaEnum,
        RheinhessenNahe: 'RHEINHESSEN_NAHE' as SupplyAreaEnum,
        Rheinpfalz: 'RHEINPFALZ' as SupplyAreaEnum,
        Trier: 'TRIER' as SupplyAreaEnum,
        Westpfalz: 'WESTPFALZ' as SupplyAreaEnum
    };
}


